<template>
<!--  <div id="nav">-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </div>-->
<!--  <router-view/>-->
  <div class="flex h-screen">
    <div class="m-auto p-20">
      <h1 class="xl:text-4xl lg:md:text-2xl md:text-1xl text-center">Die Website befindet sich derzeit im Aufbau. Bitte besuchen Sie uns bald erneut!</h1>
    </div>
  </div>
</template>

<style>

</style>
